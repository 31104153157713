import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
    merchantBalanceUsdList: null,
    currentPage: 1,
    total: 0,
    perPage: 20,
    totalInfo: {
        total_payment: 0,
        total_refund: 0,
        total_cash_out: 0,
        total_cash_in: 0,
    },
    totalBalanceUsd:{
        after_balance: 0,
    },
    disbursementBalance: {
        openingDisbursementBalance: 0,
        closingDisbursementBalance: 0,
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
