<template>
    <div class="pagetitle row">
      <div class="col-xl-6 col-xs-12">
        <h1>{{ $t(title) }}</h1>
        <nav>
          <ol class="breadcrumb">
            <li class="breadcrumb-item"  v-bind:class="{ active: breadcrumb.active }" v-for="(breadcrumb, index) in breadcrumbs" :key="index">
              <router-link :to="{ name: breadcrumb.routeName}" :disabled="!breadcrumb.active || !breadcrumb.routeName" >{{ $t(breadcrumb.name) }}</router-link>
              </li>
          </ol>
        </nav>
      </div>

      <div class="col-xl-6 col-xs-12">
        <slot></slot>
      </div>
    </div><!-- End Page Title -->
</template>

<script>
export default {
  name: 'PageTitle',
  props: {
    title: {
      type: String,
      required: true,
    },
    breadcrumbs: {
      type: Array,
      required: true,
      default: () => [
        {
          name: null,
          routeName: null
        },
      ]
    }
  },
  created: function() {
    if (this.title != undefined && this.title != '') {
      document.title = this.$t(this.title);
    }
  }
}
</script>

<style>
  .pagetitle {
    margin-top: 20px;
  }
</style>
