import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'ant-design-vue/dist/antd.css'; 

import AntDesign from 'ant-design-vue';
import vuexI18n from 'vuex-i18n';

import translationsEn from './lang/en';
import translationsVi from './lang/vi';
import { VueMaskDirective } from 'v-mask';
import VueCookies from 'vue-cookies'
import { initializeApp } from 'firebase/app';
import { getDatabase } from "firebase/database";
import ToggleButton from 'vue-js-toggle-button';
import VueMeta from 'vue-meta'

Vue.use(VueCookies)

Vue.directive('mask', VueMaskDirective);

Vue.use(AntDesign);

Vue.use(ToggleButton)

Vue.use(VueMeta)

const firebaseApp = initializeApp({
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET
});

getDatabase(firebaseApp);

Vue.config.productionTip = false;

Vue.use(vuexI18n.plugin, store);
Vue.i18n.add('en', translationsEn);
Vue.i18n.add('vi', translationsVi);
var user = store.getters["authentications/getInfoUserLogin"];
Vue.i18n.set((user && user.lang) ? user.lang : 'vi');

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
