<template>
  <div class="switch">
    <div class="text-lang">
      <span>EN &nbsp;VI</span>
    </div>
    <div class="toggle-switch">
      <input type="checkbox" @change="onChange" :checked="initLang !== AppConstant.LANGUAGE_VI"/>
      <div class="round-button">
        <div></div>
        <div></div>
        <div></div>
        <div class="button-body"></div>
      </div>
    </div>
  </div>
</template>

<script>
import AppConstant from "@/constants/App";
import {mapGetters} from "vuex";
import helper from "@/until/helper";

export default {
  name: 'SwitchButton',
  data() {
    return {
      initLang: this.lang,
      AppConstant,
    };
  },
  props: {
    lang: String,
    title: String,
    isMpos: Boolean,
    onChangeLang: Function,
  },
  metaInfo() {
    if (this.isset(this.title)) {
      return {
        title: this.title,
        meta: [
          {
            property: 'og:title',
            content: this.title
          }
        ]
      }
    }
  },
  computed: {
    ...mapGetters({
      user: 'authentications/getUserLogin'
    })
  },
  methods: {
    isset: helper.isset,
    onChange(e) {
      if (e.target.checked) {
        this.initLang = AppConstant.LANGUAGE_EN;
      } else {
        this.initLang = AppConstant.LANGUAGE_VI;
      }
      this.$i18n.set(this.initLang);
      if (!this.isMpos) {
        this.$store.dispatch("authentications/changeLanguage", {'lang': this.initLang}).then(response => {
          if (response.code == 0) {
            localStorage.setItem("user", JSON.stringify(this.user));
          }
        });

        if (this.isset(this.$route.query.hl)) {
          this.$router.push({ name: this.$route.name }).catch(() => {});
        }
      }
      this.$emit('onChangeLang', {lang: this.initLang})
    },
  },
  beforeMount() {
    if (!this.isMpos && this.user && this.user.user) {
      this.initLang = this.user.user.lang;
      let hl = this.isset(this.$route.query.hl) ? this.$route.query.hl : this.initLang;
      if (this.initLang != hl) {
        this.initLang = hl;
        this.$store.dispatch("authentications/changeLanguage", {'lang': this.initLang}).then(response => {
          if (response.code == 0) {
            localStorage.setItem("user", JSON.stringify(this.user));
          }
        });
        this.$i18n.set(this.initLang);
      }
    } else {
      let langFirst = this.isset(this.$route.query.lang) ? this.$route.query.lang : "vi";
      this.initLang = langFirst;
      this.$i18n.set(langFirst);
    }
  },
}
</script>

<style>
.switch {
  position: relative;
}
.text-lang {
  width: 43px;
  position: absolute;
  top: 2px;
  left: 4.5px;
  font-size: 11px;
  font-weight: bold;
}
.toggle-switch {
  position: relative;
  width: 43px;
  height: 20px;
  background-size: 200% 100%;
  background-position: 100% 100%;
  box-shadow: 0 0 15px 10px rgba(0, 0, 0, 0.1) inset;
  padding: 0;
  border-radius: 2.5em;
  display: flex;
  align-items: center;
  transition: 0.7s;
}
.toggle-switch input {
  height: 100%;
  width: 100%;
  cursor: pointer;
  position: absolute;
  opacity: 0;
  z-index: 999;
}
.toggle-switch .round-button {
  position: relative;
  transform: translateX(-5%);
  height: 104%;
  aspect-ratio: 1;
  display: grid;
  place-items: center;
  transition: 0.4s;
  transition-timing-function: ease-in-out;
}
.toggle-switch .round-button .button-body {
  position: absolute;
  z-index: 0;
  height: 100%;
  aspect-ratio: 1;
  border-radius: 50%;
  background-image: url('/assets/img/vietnam-flag.png');
  background-size: cover;
  background-position: center;
  box-shadow: 0 0 2px #2F2F2FFF;
}
.toggle-switch input:checked ~ .round-button {
  transform: translateX(calc(43px - 95%));
}
.toggle-switch input:checked ~ .round-button .button-body {
  background-image: url('/assets/img/english-flag.png');
  background-size: cover;
  background-position: center;
}
</style>
