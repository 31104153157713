// vi.js

export default {
    "Sidebar": {
        "Profile": "Tài khoản",
        "Balance": "Số dư",
        "BalanceFluctuationsStatistics": "Biến động số dư",
        "Withdraw": "Rút tiền",
        "TransactionManagement": "Quản lý giao dịch",
        "InvoiceManagement": "Hệ thống hóa đơn",
        "InvoiceList": "Danh sách hóa đơn",
        "AddInvoice": "Thêm mới hóa đơn",
        "TransactionList": "Tra cứu giao dịch thanh toán",
        "RefundManagement": "Yêu cầu hoàn tiền",
        "ProfileInfo": "Thông tin tài khoản",
        "ProfileManagement": "Quản lý profile",
        "StatisticsOfBalanceFluctuations": "Biến động số dư chờ đối soát",
        "PaymentSchoolManagement": "Quản lý học phí",
        "OrderPaymentList": "Danh sách thanh toán",
        "PaymentPurpose": "Mục đích thanh toán",
        "TransactionDisbursementManagement": "Chuyển tiền",
        "TransactionDisbursement": "Tra cứu giao dịch chuyển, rút",
        "MerchantDisbursementLog": "Biến động số dư khả dụng",
        "MerchantUsdLog": "Biến động số dư USD ",
        "WalletManagement": "Quản lý Ví doanh nghiệp",
        "MposManagement": "Quản lý Mpos",
        "WalletBalanceFluctuate": "Thống kê biến động ví doanh nghiệp",
        "OrdersHistoryOfUser": "Thống kê đơn hàng trên ví",
        "MposInformation": "Cấu hình thông tin Mpos",
        "Language": "Ngôn ngữ",
        "AccountDisbursementList": "Tài khoản nạp số dư khả dụng",
        "MerchantRequestDisbursement": "Chuyển tiền 247",
        "RefundInstallmentRequestManage" : "Yêu cầu hoàn tiền trả góp",
        "QRnotify": "Nhận thông báo giao dịch",
        "RechargeInformation" : "Nạp",
        "TopUpAvailableBalance" : "Tài khoản nạp số dư chờ đối soát",
        'QRManagement': 'Quản lý StoreQR',
        'QRList': 'Danh sách StoreQR',
        "QRtransactions": "Danh sách giao dịch StoreQR",
        "QRDashboard": "Dashboard tổng quan",
        "DisbursementByBatchList": "Lịch sử chuyển tiền theo Lô",
        "MerchantUsdAccount" : "Tài khoản nạp số dư USD"
    },
    "VirtualAccountUsd":{
        "Heading" : "Details of USD balance top-up account",
        "Content" : "Transfer money to your account/card number to top up your balance",
        "Bank" : "Ngân hàng ",
        "Branch" : "Chi nhánh",
        "SwiftCode" : "SwiftCode",
        "AccountNumber" : "Số tài khoản",
        "AccountName" : "Tên tài khoản",
        "ContentNote" : "Nội dung chuyển khoản ",
    },
    "MerchantBalanceUsd": {
        "OpeningDisbursementBalance" : "Số dư USD đầu kỳ",
        "ClosingDisbursementBalance" : "Số dư USD cuối kỳ",
        "TotalCash" : "Tổng số dư USD",
        "TotalCashIn" : " Nạp số dư USD",
        "TotalCashOut" : "Tổng rút số dư USD",
        "TotalBalanceDeposit" : "Số dư USD chuyển sang khả dụng",
    },
    "Title": {
        "Home": "Trang chủ",
        "CreateInvoice": "Tạo mới hóa đơn",
        "EditInvoice": "Chi tiết hóa đơn",
        "RefundList": "Danh sách yêu cầu hoàn tiền",
        "RefundDetail": "Chi tiết hoàn tiền",
        "CreateRefund": "Tạo yêu cầu hoàn tiền",
        "TransactionDetail": "Chi tiết giao dịch",
        "InvoiceDetail": "Chi tiết hóa đơn",
        "Profile": "Tài khoản",
        "Dashboard": "Dashboard",
        "CreateProfile": "Thêm mới profile",
        "EditProfile": "Sửa profile",
        "ViewProfile": "Chi tiết profile",
        "OrderPaymentDetail": "Chi tiết thanh toán học phí",
        "CreatePaymentPurpose": "Tạo mục đích thanh toán",
        "ViewPaymentPurpose": "Chi tiết mục đích thanh toán",
        "TransactionDisbursementDetail": "Chi tiết giao dịch chuyển, rút",
        "PaymentGatewayService": "Cổng thanh toán",
        "PayInPayOutService": "Thu hộ, chi hộ",
        "AccountToTopUpAvaiableBalance": "Tài khoản nạp số dư chờ đối soát",
        "Withdraw": "Rút tiền",
        "DisbursementByBatchList": "Lịch sử chuyển tiền theo Lô",
    },
    "Constant": {
        "App": {
            "BearerDisplay": {
                "Merchant": "Đại lý",
                "User": "Khách hàng",
            },
            "FeeInvoices": {
                "Seller": "Phí tính cho người bán",
                "Buyer": "Phí tính cho người mua",
            },
            "ActiveStatus": {
                "Active": "Hoạt động",
                "Inactive": "Không hoạt động",
            },
            "FeePayer": {
                "FeePayerMerchant": "Đại lý",
                "FeePayerCustomer": "Khách hàng"
            }
        },
        "MerchantUsdLogType": {
            "CashIn": "Nạp số dư USD",
            "CashOut": "Chuyển số dư USD sang số dư khả dụng",
        },
        "ChannelDisplay": {
            "Website": "Website",
            "Invoice": "Hóa đơn",
            "MerchantHosted": "Merchant Hosted"
        },
        "InstallmentStatus": {
            "Canceled": "Đã hủy trả góp",
            "Failed": "Chuyển đổi thất bại",
            "WaitSendPartner": "Chờ gửi đối tác",
            "SendPartner": "Đã gửi đến đối tác",
            "Success": "Chuyển đổi thành công",
            "SentCancelInstallment": "Đã gửi yêu cầu huỷ CĐTG",
        },
        "InvoiceStatus": {
            "Unpaid": "Chưa thanh toán",
            "Processing": "Chờ thanh toán",
            "Success": "Đã thanh toán",
        },
        "RefundStatus": {
            "Waiting": "Đang chờ",
            "Approved": "Đã duyệt",
            "Rejected": "Từ chối",
            "Placeholder": "Trạng thái",
            "Cancelled": "Đã hủy",
        },
        "RefundType": {
            "Full": "Hoàn toàn phần",
            "Partial": "Hoàn một phần",
            "Placeholder": "Loại hoàn tiền",
            "WarningRefundPartial": "Giao dịch có thể tạo hoàn 1 phần sau 24 giờ giao dịch thành công."
        },
        "TransactionMethod": {
            "AtmCard": "Thẻ nội địa",
            "CreditCard": "Thẻ quốc tế",
            "Wallet": "Ví điện tử",
            "Wallet9Pay": "Ví 9Pay",
            "WalletVNPay": "Ví VNPay",
            "WalletZaloPay": "Ví ZaloPay",
            "Collection": "Chuyển khoản ngân hàng",
            "BuyNowPayLater": "Mua trước trả sau",
            "QRPay": "QR Pay",
            "ApplePAY": "Apple Pay",
            "Installment": "Trả góp",
        },
        "TransactionStatus": {
            "Cancelled": "Hủy giao dịch",
            "Failed": "Thất bại",
            "Processing": "Đang xử lý",
            "UnderReview": "Chờ kiểm tra",
            "Linked": "Liên kết thẻ thành công",
            "Completed": "Thành công",
            "ReceivedMoney": "Đã nhận tiền",
            "Reversed" : "Hoàn trả",
        },
        "TransactionType": {
            "Purchase": "Thanh toán",
            "Refund": "Hoàn tiền",
            "Installment": "Trả góp",
            "LinkCard": "Liên kết thẻ",
            "installmentRefund": "Hoàn trả góp",
            'recharge': "Nạp tiền",
        },
        "ProfileStatus": {
            "Pending": "Chờ duyệt",
            "Approved": "Hoạt động",
            "Rejected": "Từ chối",
        },
        "MerchantBalanceLogType": {
            "Payment": "Thanh toán",
            "Refund": "Hoàn tiền",
            "CashIn": "Nạp tiền",
            "CashOut": "Rút tiền",
            "RefundFailure": "Hoàn tiền thất bại",
            "IncrementFreeze": "Đóng băng",
            "DecrementFreeze": "Mở băng",
            "LinkCard": "Liên kết thẻ",
            "LinkCardRollbackRefund": "Hoàn liên kết",
            "DisbursementPayment": "Thanh toán chuyển tiền",
            "DisbursementRefund": "Hoàn chuyển tiền",
            "DisbursementCashIn": "Nạp số dư khả dụng",
            "DisbursementCashOut": "Rút số dư khả dụng",
            "TypeTransferToDisbursement":"Chuyển số dư chờ đối soát sang số dư khả dụng",
        },
        "WalletActionType": {
            "Deposit": "Nạp tiền",
            "Transfer": "Chuyển tiền",
            "WithDraw": "Rút tiền",
            "Payment": "Thanh toán",
            "Another": "Khác",
        },
        "WalletOrderType": {
            "Billing": "Thanh toán hóa đơn",
            "GameCard": "Thẻ game",
            "LuckyBest": "Mua vé số",
            "Mobilott": "Mua vé số",
            "PhoneCard": "Mua mã thẻ điện thoại",
            "MobileNetwork": "Thẻ 3G/4G",
            "TopupData": "Nạp data 3G/4G",
            "TopupMobile": "Nạp thẻ điện thoại",
            "VBI": "Dịch vụ của VBI",
            "Vntrip": "Dịch vụ của VNTRIP",
            "ServiceCard": "Thẻ dịch vụ",
        },
        "WalletOrderStatus": {
            "Success": "Hóa đơn thành công",
            "Fail": "Hóa đơn thất bại",
            "Cancel": "Đã hủy",
            "Created": "Đã khởi tạo",
            "ExpiredSession": "Hóa đơn hết hạn",
            "Processing": "Đang xử lý",
            "PaymentSuccess": "Thanh toán thành công",
            "PaymentPending": "Đợi thanh toán",
            "Another": "Khác",
        },
        "TransBankInfo": {
            "CheckEnroll": "Enroll",
            "PayerAuth": "Payer Authentication",
            "Authorize": "Authorize",
            "Capture": "Capture",
            "ReverseAuth": "Reverse Authorize",
            "Success": "Thành công",
            "Fail": "Thất bại",
            "Processing": "Đang xử lý",
            "Pending": "Đang chờ",
            "Reversal": "Hoàn trả",
            "NotOccur": "Chưa thực hiện",
            "Bypass": "Bypass",
        },
        "RefundInstallmentReqStt": {
            "PENDING": "Chờ duyệt",
            "SUCCESS": "Đã duyệt",
            "SENT": "Đã tạo yêu cầu hoàn",
            "CANCEL": "Đã hủy",
            "FAIL": "Hoàn lỗi",
            "Placeholder": 'Trạng thái'
        },
        "RefundInstallmentRequested": {
            "no_fee_refund_installment_requested": "Không yêu cầu hoàn phí CĐTG",
            "fee_refund_installment_requested": "Yêu cầu hoàn phí CĐTG",
        },
        "VAStatus": {
            "INACTIVE": "Không hoạt động",
            "ACTIVE": "Hoạt động"
        },
        "DisbursementType": {
            "TRANSFER": "Chuyển tiền",
            "WITHDRAW": "Rút tiền"
        }
    },
    "Label": {
        "FeePayerTitle": "Đối tượng chịu phí",
        "TransactionInfo": "Thông tin giao dịch",
        "ProfileInfo": "Thông tin Profile",
        "CustomerInfo": "Thông tin khách hàng",
        "PaymentLinkInfo": "Link thanh toán",
        "InvoiceLink": "Link hóa đơn",
        "TransactionPaymentInfo": "Thông tin giao dịch thanh toán",
        "RefundInfo": "Thông tin yêu cầu hoàn tiền",
        "MerchantInfo": "Thông tin khách hàng",
        "AccountInfo": "Thông tin tài khoản",
        "InvoiceInfo": "Thông tin hóa đơn",
        "CardInfo": "Thông tin thẻ thanh toán",
        "InstallmentInfo": "Thông tin trả góp",
        "RefundRequest": "Yêu cầu hoàn tiền",
        "AvailableBalances": "Số dư chờ đối soát",
        "FilterByMerchant": "Lọc theo đại lý:",
        "PaymentLink": "Link thanh toán",
        "TheSellerLink": "Link người bán chịu phí - Tiếng Việt",
        "TheBuyerLink": "Link người mua chịu phí - Tiếng Việt",
        "TheSellerLinkEn": "Link người bán chịu phí - Tiếng Anh",
        "TheBuyerLinkEn": "Link người mua chịu phí - Tiếng Anh",
        "FeeTransaction": "Phí giao dịch",
        "Language": "Ngôn ngữ thanh toán",
        "StartDate": "Ngày bắt đầu",
        "EndDate": "Ngày kết thúc",
        "SignOut": "Đăng xuất",
        "LinkZaloAccount": "Cấu trúc liên kết Zalo",
        "FreezeBalance": "Số dư đóng băng",
        "MerchantBalance": "Số dư đại lý (VND)",
        "DisbursementBalance": "Số dư khả dụng",
        "DisbursementBalanceMax": "Số tiền chi hộ tối đa",
        "TheSchoolPaymentLink": "Link thanh toán học phí",
        "TitleQRCode": "Sử dụng camera điện thoại quét để truy cập vào link thanh toán",
        "PurchaseNumber": "Số lần thanh toán",
        "AddCustomer": "Thêm khách hàng",
        "AddProduct": "Thêm sản phẩm",
        "TransactionStatus": "Trạng thái giao dịch",
        "LearnMoreStatus": "Tìm hiểu thêm về các trạng thái",
        "Explain": "Giải thích",
        "WalletBalance": "Số dư ví doanh nghiệp",
        "PaymentProcessingInfo": "Thông tin xử lý thanh toán",
        "Overview": "Các bước thực hiện giao dịch",
        "Detail": "Chi tiết các bước thực hiện",
        "CreatedBy": "Người tạo",
        "QRCreation": "Tạo mới StoreQR",
        "QRUpdate": "Cập nhật StoreQR",
    },
    "Button": {
        "Create": "Tạo mới",
        "Back": "Quay lại",
        "Search": "Tìm kiếm",
        "Cancel": "Hủy bỏ",
        "CancelRefund": "Hủy hoàn tiền",
        "Update": "Cập nhật",
        "Add": "Thêm mới",
        "Edit": "Sửa",
        "Save": "Lưu",
        "AddAccount": "Thêm tài khoản",
        "SendRequest": "Gửi yêu cầu",
        "Permission": "Phân quyền",
        "Refund": "Hoàn tiền",
        "Upload": "Tải lên",
        "Copy": "Sao chép link",
        "OK": "Đồng ý",
        "Export": "Export",
        "ExportHistory": "Lịch sử export",
        "SaveConfig": "Lưu cài đặt",
        "GenerateQRCode": "Tạo mã QR code",
        "SaveImage": "Lưu ảnh",
        "Close": "Đóng",
        "CancelInstallment": "Huỷ CĐTG",
        "Download": "Tải xuống",
        "Confirm" : "Xác nhận",
        "CreateQR": "Thêm mới chi nhánh",
        "ConfirmTransfer": "Xác nhận chuyển tiền"
    },
    "Message": {
        "Required": "{name} là bắt buộc",
        "Unique": "{name} đã tồn tại",
        "MinLength": "{name} phải lớn hơn {length} ký tự",
        "MaxLength": "{name} phải nhỏ hơn {length} ký tự",
        "AmountMinVND": "Giá thành sản phẩm ít nhất là 1.000 vnđ",
        "AmountMinUSD": "Giá thành sản phẩm ít nhất là 1 usd",
        "ConfirmPasswordNotMatch": "Mật khẩu nhập lại không trùng khớp",
        "PasswordIsNotCorrect": "Mật khẩu hiện tại không đúng",
        "PasswordRegex": "Mật khẩu phải có chữ thường, chữ hoa, số và ký tự @$!%*#?&",
        "UsernameRegex": "Tên đăng nhập chỉ cho phép chữ thường, chữ hoa, số và ký tự \"_\"",
        "TransactionDoesNotExist": "Giao dịch không tồn tại, mời bạn nhập mã giao dịch khác",
        "YouHaveOnlyCompletedASuccessfulPaymentTransaction": "Bạn chỉ hoàn được giao dịch thanh toán thành công, mời bạn nhập mã giao dịch khác",
        "NotSupportRefund": "Giao dịch không hỗ trợ hoàn tiền",
        "TransactionsRefundRequestWaiting": "Giao dịch đang chờ duyệt hoàn tiền, mời bạn nhập mã giao dịch khác",
        "TransactionsHaveBeenRefundedPastTime": "Giao dịch đã quá thời gian được hoàn tiền {timeAllow} ngày",
        "TheTransactionHasBeenFullyRefunded": "Giao dịch đã được hoàn tiền hết số tiền đã thanh toán, mời bạn nhập mã giao dịch khác",
        "AmountRefundMin": "Số tiền muốn hoàn ít nhất là 1.000 vnđ",
        "TheRefundAmountCannotBeGreaterThanTheTransactionAmount": "Số tiền hoàn không được lớn hơn số tiền giao dịch",
        "YouCanRefundFullWhenNotOver24h": "Sau 24 giờ kể từ khi giao dịch thành công, bạn có thể thực hiện hoàn tiền một phần.",
        "CanNotRefundRequest": "Giao dịch không thể tạo yêu cầu hoàn tiền",
        "UploadTypeNotAccept": "{name} có loại tệp không hợp lệ",
        "UploadSizeMax1MB": "{name} phải < 1MB",
        "UploadSizeMax1Dot5MB": "{name} phải < 1.5MB",
        "Invalid": "{name} không hợp lệ",
        "AlreadyExists": "{name} đã tồn tại",
        "BankAccountNumberRegex": "Chỉ cho phép chữ hoa và số",
        "AlphaNumeric": "{name} chỉ cho phép nhập chữ không dấu và số",
        "AmountMin": "Số tiền giao dịch tối thiểu là 1 VND",
        "AmountMax": "Số tiền giao dịch tối đa là {amountMax} VND",
        "AmountMinByLimit": "Số tiền giao dịch tối thiểu là {amountMinByLimit} VND",
        'RefundInstallmentRequestAlert': 'Không thể tạo yêu cầu hoàn tiền cho giao dịch do có yêu cầu hoàn tiền đang xử lý',
    },
    "Notice": {
        "Title": {
            "Confirm": "Xác nhận",
            "Notice": "Thông báo",
            "SureTodelete": "Bạn có chắc chắn xóa không?",
        },
        "Message": {
            "ConfirmDeleteInvoice": "Bạn có chắc chắn muốn xóa hóa đơn này?",
            "CreateRefundSuccess": "Thêm mới yêu cầu hoàn tiên thành công",
            "CreateRefundFail": "Thêm mới yêu cầu hoàn tiên thất bại",
            "CreateInvoiceSuccess": "Thêm mới hóa đơn thành công",
            "CreateInvoiceFail": "Thêm mới hóa đơn thất bại",
            "UpdateInvoiceSuccess": "Sửa hóa đơn thành công",
            "UpdateInvoiceFail": "Sửa hóa đơn thất bại",
            "CopySuccess": "Copy thành công",
            "PermissionSuccess": "Phân quyền thành công",
            "PermissionFail": "Phân quyền thất bại",
            "CreateUserSuccess": "Thêm mới tài khoản thành công",
            "CreateUserFail": "Thêm mới tài khoản thất bại",
            "EditPasswordSuccess": "Đổi mật khẩu tài khoản thành công",
            "EditPasswordSuccessAndReload": "Đổi mật khẩu tài khoản thành công. Hệ thống sẽ tự khởi động lại",
            "CreateProfileSuccess": "Thêm mới Profile thành công",
            "CreateProfileFail": "Thêm mới Profile thất bại",
            "EditProfileSuccess": "Sửa Profile thành công",
            "EditProfileFail": "Sửa Profile thất bại",
            "ConfirmDeleteProfile": "Bạn có chắc chắn muốn xóa Profile",
            "DeleteProfileSuccess": "Xóa Profile thành công",
            "DeleteProfileFail": "Xóa Profile thất bại",
            "DeleteInvoiceSuccess": "Xóa hóa đơn thành công",
            "DeleteInvoiceFail": "Xóa hóa đơn thất bại",
            "ProductInvalid": "Vui lòng nhập thông tin bắt buộc của sản phẩm",
            "AmountLessVND": "Tổng thanh toán không được nhỏ hơn 10.000 VND",
            "AmountGreaterVND": "Tổng thanh toán không được lớn hơn 500.000.000 VND",
            "AmountLessCurrency": "Tổng thanh toán ngoại tệ không được nhỏ hơn 15.000 VND",
            "AmountGreaterCurrency": "Tổng thanh toán ngoại tệ không được lớn hơn 499.000.000 VND",
            "NotHaveAuthorizedCreateRefund": "Bạn không có quyền tạo giao dịch hoàn",
            "ProductLimit": "Bạn chỉ được tạo tối đa 20 sản phẩm trong 1 hóa đơn",
            "TimeExportLimit": "Vui lòng chọn thời gian xuất dữ liệu, tối đa 31 ngày",
            "MakingARequestToExportData": "Hệ thống đang thực hiện yêu cầu xuất dữ liệu. Vui lòng không tắt trình duyệt",
            "NotEnoughBalance": "Số dư không đủ để tạo yêu cầu",
            "MaxLengthProductName": "Tên sản phẩm không được nhiều hơn 255 ký tự",
            "SavePaymentPurposeSuccess": "Lưu mục đích thanh toán thành công",
            "SavePaymentPurposeFail": "Lưu mục đích thanh toán thất bại",
            "UpdateStatusInvoiceSuccess": "Sửa trạng thái hóa đơn thành công",
            "ConfirmActiveInvoice": "Bạn có chắc chắn hoạt động hóa đơn này?",
            "ConfirmInactiveInvoice": "Bạn có chắc chắn dừng hoạt động hóa đơn này?",
            "ConfirmCancelRefund": "Bạn có chắc chắn hủy giao dịch hoàn tiền này?",
            "CancelRefundSuccess": "Hủy giao dịch hoàn tiền thành công",
            "CancelRefundFail": "Hủy giao dịch hoàn tiền thất bại",
            "InvalidAccountInformation": "Thông tin tài khoản không hợp lệ",
            "ValidAccountInformation": "Thông tin tài khoản hợp lệ",
            "NotEnableGoogle2FA": "Vui lòng bật xác thực 2 yếu tố",
            "InvalidCodeGoogle2FA": "Mã xác thực không chính xác",
            "CreateRequestDisbursementFail": "Tạo yêu cầu chuyển tiền thất bại",
            "CreateRequestDisbursementSuccess": "Tạo yêu cầu chuyển tiền thành công",
            "LinkLimit": "Bạn chỉ được tạo tối đa 6 liên kết",
            "UpdateLinkInfoSuccess": "Cập nhật thông tin thành công",
            "UpdateLinkInfoFail": "Cập nhật thông tin thất bại",
            "InvalidBankCMS": "Ngân hàng đã chọn hiện đang được bảo trì, vui lòng chọn ngân hàng khác",
            "ConfirmSendRequestCancelInstallment": "Xác nhận yêu cầu huỷ chuyển đổi trả góp",
            'NotiCancelInstallmentSuccess': 'Yêu cầu huỷ chuyển đổi trả góp thành công',
            'NotiCancelInstallmentFail': 'Yêu cầu huỷ chuyển đổi trả góp không thành công',
            'CreateRequestRefundSuccess': 'Tạo yêu cầu hoàn tiền cho giao dịch trả góp thành công',
            'CreateQRSuccess': 'Tạo StoreQR thành công',
            'UpdateQRSuccess': 'Cập nhật StoreQR thành công',
            'CreateQRFail': 'Tạo StoreQR thất bại',
            'UpdateQRFail': 'Cập nhật StoreQR thất bại',
            'QRExist': 'StoreQR đã tồn tại',
            'QRNotExist': 'StoreQR không tồn tại',
            'NotEnableWithdraw': 'Đối tác chưa được hỗ trợ sử dụng chức năng này. Vui lòng liên hệ 9Pay để được hỗ trợ.',
            'TransactionHoldMoney': "Không thể tạo hoàn tiền do giao dịch đang trong diện nghi ngờ gian lận",
            'TransactionSuccessful': "Chuyển tiền thành công",
            'TransactionFail': 'Chuyển tiền thất bại',
            'ExceedsTransactionLimit': 'Giao dịch nằm ngoài hạn mức chuyển tiền',
            "NotEnoughAvailableBalance": "Số dư khả dụng không đủ để thực hiện yêu cầu",
            "BatchIsCompleted": "File chuyển tiền đã được thực hiện, vui lòng kiểm tra"
        },
    },
    "Modal": {
        "Title": {
            "EditPassword": "Đổi mật khẩu tài khoản {username}",
            "CreateSimpleAccount": "Thêm tài khoản thường",
            "SelectPermission": "Lựa chọn phân quyền: {username}",
        },
    },

    /* Module detail */
    "Invoice": {
        "InvoiceCode": "Mã hóa đơn",
        "ProductNameOrService": "Tên sản phẩm/dịch vụ",
        "ProductName": "Tên sản phẩm",
        "Currency": "Tiền tệ",
        "Amount": "Giá thành sản phẩm",
        "ProductDescription": "Mô tả sản phẩm",
        "PaymentStatus": "Trạng thái thanh toán",
        "UserUpdate": "Người cập nhật",
        "UpdateDate": "Ngày cập nhật",
        "Action": "Hành động",
        "StatusInvoice": "Trạng thái hóa đơn",
        "Profile": "Chọn profile",
        "Title": "Tiêu đề",
        "CreateDate": 'Ngày tạo',
        "Note": "Ghi chú hóa đơn",
        "Policy": "Thêm các điều khoản",
        "AutoGen": "Tiếp tục tự động tạo mã hóa đơn",
        "MannualGen": "Tôi sẽ thêm chúng theo cách thủ công mỗi lần thực hiện",
        "Prefix": "Tiền tố",
        "InvoiceCodeContinue": "Số tiếp theo",
        "ProductDetail": "Chi tiết sản phẩm",
        "Quantity": "Số lượng",
        "Price": "Đơn giá",
        "Money": "Thành tiền",
        "Temporary": "Tạm tính",
        "Discount": "Chiết khấu",
        "Adjusted": "Nội dung",
        "AdjustedNumber": "Số tiền",
        "Total": "Tổng",
        "AdjustedTooltip": "Thêm bất cứ khoản phí + hoặc - nào cần áp dụng để điều chỉnh tổng số tiền giao dịch, ví dụ 10 hoặc -10",
        "Permanent": "Cố định",
        "DateCreate": "Ngày tạo",
        "Default": "Mặc định",
        "AmountMpos": "Tổng thanh toán",
        "No": "STT",
        "DetailOrder": "Chi tiết đơn hàng",
        "TotalAmount": "Tổng tiền",
        "One": "Một lần",
        "Multiple": "Nhiều lần",
        "AdjustedText": "Điều chỉnh",
        "profileBlockedAlert": "Profile này hiện đang tắt/xoá, vui lòng chọn tuỳ chọn khác hoặc bỏ chọn.",
        "PaymentRequestInternationalCard": "Yêu cầu thanh toán thẻ quốc tế",
        "ProvideAddress": "Cung cấp địa chỉ",
        "ProvideEmail": "Cung cấp email",
        "NotiExchangeRate": "Tỷ giá có thể thay đổi theo quy định của ngân hàng phát hành thẻ tại thời điểm thanh toán.",
        "InvoiceMinimumSupported": "hỗ trợ số tiền tối thiểu",
        "InvoiceMaximumSupported": "và tối đa",
        "Warning": "Lưu ý",
        "Preview": "Xem trước",
        "HidePreview": "Ẩn xem trước",
    },
    "PreviewInvoice": {
        'TitleConfirm': 'Xác nhận thanh toán',
        'TitlePage': 'Xác nhận thanh toán',
    },
    "Customer": {
        "Name": "Họ và tên",
        "CustomerName": "Tên khách hàng",
        "Phone": "Số điện thoại",
        "Address": "Địa chỉ",
        "Email": "Email",
        // "Country": "Quốc gia",
    },
    "Language": "Ngôn ngữ",
    "Transaction": {
        // search form
        "OrderAndRequestCode": "Mã yêu cầu/ Mã giao dịch",
        "CardNumber": "Số thẻ",
        "CardName": "Tên thẻ",
        "Channel": "Kênh thanh toán",
        "Status": "Trạng thái",
        "Method": "Phương thức thanh toán",
        "Type": "Loại giao dịch",
        // header table
        "No": "STT",
        "TradingDate": "Ngày giao dịch",
        "CreatedAt": "Thời gian khởi tạo",
        "UpdatedAt": "Thời gian cập nhật",
        "OrderCode": "Mã giao dịch",
        "RequestCode": "Mã yêu cầu",
        "RequestAndContractCode": "Mã yêu cầu/Mã HĐ",
        "TypeTh": "Loại giao dịch",
        "ChannelTh": "Kênh thanh toán",
        "MethodTh": "Phương thức thanh toán",
        "BankAndCardNumber": "Ngân hàng/ Số thẻ",
        "Amount": "Giá trị giao dịch",
        "ReferenceValueAndRateVND": "Giá trị tham chiếu/Tỷ giá VND",
        "FeeVND": "Phí",
        "ClientIP": "Client IP",
        "Remark":"Nội dung chuyển khoản",
        "SenderBankName":"Ngân hàng chuyển khoản",
        "SenderBankAccountName":"Tên người chuyển khoản",
        "SenderBankAccountNo":"STK chuyển khoản",
        "MerchantName": "Đại lý",
        "UpdatedBy": "Người tạo hóa đơn",
        // detail
        "PaymentValue": "Giá trị giao dịch",
        "Currency": "Giá trị ngoại tệ",
        "ExcRate": "Tỷ giá",
        "ChargePaymentFee": "Phí thanh toán",
        "RefundPaymentFee": "Phí thanh toán hoàn trả",
        "InstallmentPaymentFee": "Phí thanh toán trả góp",
        "ChargeProcessingFee": "Phí xử lý",
        "AmountActually": "Số tiền thực nhận",
        "ActualAmountOfRefund": "Số tiền thực hoàn",
        "PaymentMethod": "Phương thức thanh toán",
        "RefundType": "Loại hoàn tiền",
        "FailureReason": "Lý do thất bại",
        "InstallmentFee": "Phí chuyển đổi trả góp",
        "ActualAmountMinus": "Số tiền thực trừ",
        "ChooseColumn": "Hiển thị dữ liệu",
        "SelectAll": "Tất cả",
        "SelectCreator": "Người tạo hóa đơn",
        "TotalTransaction": "Tổng số lượng giao dịch",
        "TotalFeeTransaction": "Tổng phí giao dịch",
        "TotalAmountTransaction": "Tổng giá trị giao dịch thành công",
        "TotalAmountReceived": "Tổng số tiền thực nhận",
    },
    "Refund": {
        "RequestAndPaymentCode": "Mã giao dịch/Mã yêu cầu",
        "Time": "Thời gian",
        "PaymentNo": "Mã GD",
        "PaymentOriginCode": "Mã GD gốc",
        "PaymentOriginValue": "Giá trị GD gốc",
        "RefundType": "Loại hoàn tiền",
        "AmountRefund": "Số tiền hoàn",
        "Status": "Trạng thái",
        "RequestCode": "Mã yêu cầu",
        "ReceivePaymentFee": "Phí thanh toán hoàn trả",
        "ReceiveProcessingFee": "Phí xử lý GD hoàn",
        "Description": "Lý do",
        "RequestDate": "Ngày yêu cầu",
        "AmountRefunded": "Số tiền đã hoàn",
        "AmountWantRefund": "Số tiền muốn hoàn",
        "No": "STT",
        "Action": "Hành động",
        "MaximumRefundAmount": 'Số tiền hoàn tối đa',
        "MaximumRefundAmountTooltip": "Số tiền hoàn tối đa = Giá trị giao dịch - Số tiền đã hoàn - Phí CĐTG khách hàng chịu",
        "InstallmentConversionFee": "Phí Trả góp",
        "InstallmentConversionRefundRequest": "Yêu cầu hoàn phí chuyển đổi trả góp",
        "InstallmentConversionRefundRequestTooltip": "Merchant có thể yêu cầu hoàn phí trả góp, tuy nhiên 9Pay sẽ kiểm tra với ngân hàng để xác nhận có thể hoàn trả phí",
        "AmountRefundedTooltip": "Số tiền đã hoàn bao gồm số tiền đã hoàn và Số tiền đã yêu cầu hoàn",
        "Include": "Bao gồm",
    },
    "BankInfo": {
        "CardName": "Tên chủ thẻ",
        "CardNumber": "Số thẻ",
        "BankCode": "Thương hiệu thẻ",
        "BankName": "Ngân hàng chuyển khoản",
        "ChooseBank": "Chọn ngân hàng",
        "BankAccountNumber": "Số tài khoản",
        "AccountHolderName": "Họ và tên chủ tài khoản",
    },
    "Profile": {
        "Tab": {
            "Info": "Thông tin",
            "MemberAccount": "Tài khoản thành viên",
            "PaymentLinkInfo": "Thông tin link thanh toán",
        },
        "No": "STT",
        "CreateDate": "Ngày tạo",
        "ProviderName": "Tên nhà cung cấp",
        "IDProfile": "ID profile",
        "ProviderLogo": "Logo nhà cung cấp",
        "Address": "Địa chỉ",
        "ProviderWebsite": "Website nhà cung cấp",
        "Phone": "Số điện thoại",
        "Email": "Email",
        "TermsAndPolicy": "Điều khoản và chính sách NCC",
        "ReasonForRefusal": "Lý do từ chối",
        "Status": "Trạng thái",
        "Action": "Thao tác",
        "PaymentLinkInfoText": {
            "StallName": "Tên gian hàng",
            "StoreInfo": "Thông tin cửa hàng",
            "Description": "Mô tả",
            "DescriptionVn": "Nhập mô tả tiếng việt...",
            "DescriptionEn": "Nhập mô tả tiếng anh...",
            "LinkManagement": "Quản lý liên kết",
            "LinkTotal": "Tổng số liên kết",
            "AddLink": "Thêm liên kết",
            "Link": "Liên kết trang",
            "SelectLink": "Chọn liên kết",
        },
        "IsActive": "Bật/tắt profile"
    },
    "Merchant": {
        "Name": "Tên doanh nghiệp",
        "RepresentativeName": "Tên người đại diện",
        "BusinessRegistrationNumber": "Số ĐKKD",
        "Phone": "Số điện thoại",
        "Address": "Địa chỉ",
        "TaxCode": "Mã số thuế",
        "Email": "Email",
        "IPNUrl": "IPN Url",
        "ReturnUrl": "Return Url",
    },
    "Account": {
        "Name": "Tên tài khoản",
        "Password": "Mật khẩu",
        "No": "STT",
        "Username": "Tên đăng nhập",
        "Action": "Chức năng",
        "CurrentPassword": "Mật khẩu hiện tại",
        "PasswordConfirm": "Nhập lại mật khẩu",
        "MerchantPassword": "Mật khẩu Merchant",
        "SecurityTwoFactor": "Xác thực 2 yếu tố" ,
        "SignTwoFactor": "Đăng ký",
        "StatusOn": "Bật",
        "StatusOff": "Tắt",
        "CancelTwoFactor": "Hủy đăng ký"
    },
    "Installment": {
        "CardName": "Tên chủ thẻ",
        "BankInstallment": "Ngân hàng phát hành",
        "Period": "Kỳ hạn",
        "PeriodShow": "{period} tháng",
        "FeeBearer": "Đối tượng chịu phí",
        "ReceivedFee": "Phí trả góp",
        "RefundReceivedFee": "Phí trả góp được hoàn trả",
        "ClientPhone": "SĐT Khách hàng",
        "Status": "Trạng thái CĐTG",
        "Note": "Ghi chú",
    },
    "Dashboard": {
        "Revenue": "Doanh Thu",
        "Refund": "Hoàn Tiền",
        "Today": "Hôm nay",
        "ThisWeek": "Tuần này",
        "ThisMonth": "Tháng này",
        "ThisQuarter": "Quý này",
        "ByDay": "Theo ngày",
        "ByWeek": "Theo tuần",
        "ByMonth": "Theo tháng",
        "TotalDeposit": "Tổng Nạp",
        "TotalWithdrawals": "Tổng rút đã duyệt",
        "AllMerchant": "Tất cả đại lý",
        "Transactions": "giao dịch",
        "TransactionStatistics": "Thống kê giao dịch:",
        "DepositAndWithdrawalStatistics": "Thống kê nạp rút:",
        "ExchangeRate": "Tỷ giá ngoại tệ:",
        "Week": "Tuần",
        "Month": "Tháng",
        "GMV": "GMV (VNĐ)",
        "NumberOfTransactions": "Số lượng giao dịch",
        "Currency": "Tên ngoại tệ",
        "CurrencyCode": "Mã ngoại tệ",
        "VND": "Tiền VND",
        "RevenueReport": "Báo cáo doanh thu",
        "TopRevenue": "Top doanh thu",
        "TotalRevenue": "Tổng doanh thu",
        "RevenueRate": "Tỷ lệ doanh thu",
        "Yesterday": "Hôm qua",
        "LastMonth": "Tháng trước",
        "ThisYear": "Năm nay",
        "Others": "Còn lại",
        "AvailableBalance": "Số dư khả dụng",
        "AlertFilterDateQR": "Vui lòng chỉ chọn tối đa 31 ngày",
        "TodaysRevenue": "Doanh thu hôm nay",
        "Withdrawals": "Rút tiền",
        "RecentTransactions": "Giao dịch gần đây",
        "ViewAll": "Xem tất cả",
        "SelectDate": "Chọn ngày",
        "QRRevenue": "Doanh thu",
    },
    "MerchantBalanceLog": {
        "AllType": "Tất cả biến động",
        // Total info
        "TotalPayment": "Tổng thanh toán",
        "TotalRefund": "Tổng hoàn tiền",
        "TotalIncrease": "Phát sinh tăng",
        "TotalReduced": "Phát sinh giảm",
        "TotalCashOut": "Tổng nạp",
        "TotalCashIn": "Tổng rút",
        "OpeningAvailableBalance": "Số dư chờ đối soát đầu kỳ",
        "ClosingAvailableBalance": "Số dư chờ đối soát cuối kỳ",
        "FreezeBalance": "Số dư đóng băng",
        "OpenFreezeBalance": "Đã mở băng",
        // Table
        "No": "STT",
        "MerchantName": "Tên đại lý",
        "CreateDate": "Ngày tạo",
        "Type": "Loại biến động",
        "BeforeBalance": "Trước cập nhật",
        "Amount": "Giá trị cập nhật",
        "AfterBalance": "Sau cập nhật",
        "Description": "Nội dung",
    },
    "MerchantDisbursementLog": {
        "AllType": "Tất cả biến động",
        // Total info
        "TotalPayment": "Tổng giao dịch thanh toán chuyển tiền",
        "TotalRefund": "Tổng giao dịch thanh toán chuyển tiền",
        "TotalCashOut": "Tổng rút số dư khả dụng",
        "TotalCashIn": "Tổng nạp số dư khả dụng",
        "OpeningDisbursementBalance": "Số dư khả dụng đầu kỳ",
        "ClosingDisbursementBalance": "Số dư khả dụng cuối kỳ",
        // Table
        "No": "STT",
        "MerchantName": "Tên đại lý",
        "CreateDate": "Ngày tạo",
        "Type": "Loại biến động",
        "BeforeBalance": "Trước cập nhật",
        "Amount": "Giá trị cập nhật",
        "AfterBalance": "Sau cập nhật",
    },
    "ShowPaginateFooter": "Hiển thị {total_row} của tổng {total} giao dịch",
    "ShowPaginateFooterInvoice": "Hiển thị {total_row} của tổng {total} hóa đơn",
    "ShowPaginateFooterProfiles": "Hiển thị {total_row} của tổng {total} profiles",
    "ShowPaginateFooterRecords": "Hiển thị {total_row} của tổng {total} bản ghi",
    "Mpos": {
        'title': "Thông tin thanh toán",
        "amount": "Số tiền thanh toán*",
        "telephone": "Số điện thoại",
        "description": "Mô tả (tối đa 255 ký tự)",
        "textCondition": "Tôi đã đọc và đồng ý với ",
        "linkTextCondition": "điều khoản và điều kiện.",
        "pay": "Thanh toán",
        "payInstallment": "Thanh toán trả góp",
        'PayerInformation': "Thông tin người thanh toán",
        'Email': "Email",
        'Address': "Địa chỉ",
        'TooltipVerify': "Đối tác được xác thực bởi 9Pay",
        "validate": {
            "amount": "Số tiền thanh toán không được để trống.",
            "telephone": "Số điện thoại không được để trống.",
            "min": "Số tiền thanh toán tối thiểu là {min} {currency}",
            "max": "Số tiền thanh toán tối đa là {max} {currency}",
            "phoneInvalid": "Số điện thoại không chính xác"
        }
    },
    "PermissionGroup": {
        "manageProfile": "Quản lý tài khoản",
        "manageTransaction": "Quản lý giao dịch",
        "manageRefund": "Yêu cầu hoàn tiền",
        "manageSchoolPayment": "Quản lý học phí",
        "manageTransactionDisbursement": "Chuyển tiền",
        "manageWallet": "Quản lý Ví doanh nghiệp",
        "manageMpos": "Quản lý Mpos",
        "manageRechargeAccount": "Nạp",
        "manageQR": "Quản lý StoreQR",
        "manageBalance": "Rút",
        "balanceFluctuationsStatistics": "Xem thống kê biến động số dư"
    },
    "Permission": {
        "canViewProfile": "Xem thông tin thành viên",
        "canViewListProfile": "Xem danh sách profile",
        "canViewDetailProfile": "Xem chi tiết profile",
        "canAddProfile": "Thêm mới profile",
        "canEditProfile": "Chỉnh sửa profile",
        "canDeleteProfile": "Xóa profile",
        "canViewListInvoice": "Xem danh sách hóa đơn",
        "canViewDetailInvoice": "Xem chi tiết hóa đơn",
        "canAddInvoice": "Thêm mới hóa đơn",
        "canEditInvoice": "Sửa hóa đơn",
        "canDeleteInvoice": "Xóa hóa đơn chưa thanh toán",
        "canViewListTransaction": "Xem danh sách giao dịch",
        "canViewDetailTransaction": "Xem chi tiết giao dịch",
        "canViewBalanceFluctuationsStatistics": "Biến động số dư chờ đối soát",
        "canViewListRefund": "Xem danh sách yêu cầu hoàn tiền",
        "canViewDetailRefund": "Xem chi tiết hoàn tiền",
        "canAddRefund": "Thêm mới và hủy bỏ yêu cầu hoàn tiền",
        "canViewOrderPaymentPageList": "Xem danh sách thanh toán",
        "canViewOrderPaymentPageDetail": "Xem chi tiết thanh toán",
        "canViewListPaymentPurpose": "Xem danh sách mục đích thanh toán",
        "canViewDetailPaymentPurpose": "Xem chi tiết mục đích thanh toán",
        "canAddPaymentPurpose": "Thêm mới mục đích thanh toán",
        "canEditPaymentPurpose": "Sửa mục đích thanh toán",
        "canViewTransactionDisbursementList": "Xem danh sách giao dịch chuyển, rút",
        "canViewTransactionDisbursementDetail": "Xem Chi tiết giao dịch chuyển, rút",
        "canViewMerchantDisbursementLog": "Biến động số dư khả dụng",
        "canViewMerchantUsdLog": "Biến động số dư Usd",
        "canViewWalletBalanceFluctuate": "Xem thống kê biến động ví doanh nghiệp",
        "canViewUserOrderHistory": "Xem thống kê đơn hàng trên ví",
        "canViewListRefundInstallmentRequest": "Xem danh sách yêu cầu hoàn trả góp",
        "canViewPaymentLinkInfo": "Xem cấu hình thông tin Mpos",
        "canUpdatePaymentLinkInfo": "Chỉnh sửa cấu hình thông tin Mpos",
        "canAddMerchantRequestDisbursement": "Chuyển tiền 247",
        "canViewAccountDisbursementList": "Xem tài khoản nạp số dư khả dụng",
        "canViewOnlyInvoicesGeneratedByAccount": "Chỉ xem hóa đơn được tạo bởi tài khoản",
        "canViewTopUpAvailableBalance": "Xem tài khoản nạp số dư chờ đối soát",
        "canViewQRList": "Xem danh sách StoreQR",
        "canAddQR": "Thêm mới StoreQR",
        "canEditQR": "Sửa tài StoreQR",
        "canViewListTransactionsQR": "Xem danh sách giao dịch QR",
        "canViewDetailTransactionsQR": "Xem chi tiết giao dịch QR",
        "canRefundTransactionsQR": "Tạo lệnh hoàn tiền giao dịch QR",
        "canViewNotificationQR": "Xem danh sách nhận thông báo giao dịch",
        "canAddMember":"Thêm/sửa/xoá thành viên",
        "canWithdrawBalance": "Thao tác rút tiền"
    },
    "Tooltip": {
        "Delete": "Xóa",
        "Duplicate": "Nhân đôi",
        "View": "Chi tiết",
        "ViewTransaction": "Tra cứu",
        "Edit": "Chỉnh sửa",
        "InfoProfile": "Thông tin nhà cung cấp sẽ tự động lấy theo thông tin của tài khoản đại lý nếu không chọn Profile",
        "Active": "Hoạt động",
        "Inactive": "Dừng hoạt động",
        "Cancel": "Hủy bỏ",
        "Installment": "Khi chọn Trả góp bắt buộc phải chọn thẻ Quốc Tế",
        "QRCode": "Mã QR",
        "Refund": "Hoàn tiền",
        "CheckInfo": "Kiểm tra thông tin",
        "TransferMoney": "Chuyển tiền",
        "CheckAccount": "Chỉ kiểm tra tài khoản có dữ liệu hợp lệ"
    },
    "PageNotFound": {
        "Content": "Quý khách chưa có quyền sử dụng chức năng này. Vui lòng liên hệ nhà cung cấp đơn hàng của bạn để được hỗ trợ về đơn hàng. Xin trân trọng cám ơn."
    },
    "PaymentSchool": {
        "PaymentContent": "Nội dung thanh toán",
        "AddRulePayment": "Thêm các khoản thanh toán",
        "TransactionFee": "Phí thanh toán giao dịch",
        "AddLine": "Thêm dòng mới",
        "ConfirmAction": "Bạn chắc chắn muốn thực hiện thao tác này không?",
        "StudentInfo": "Thông tin học sinh",
        "PaymentPurpose": "Mục đích thanh toán",
        "StudentName": "Tên học sinh",
        "StudentCode": "Mã học sinh",
        "Address": "Cơ sở",
        "Grade": "Khối",
        "TransactionInfo": "Thông tin giao dịch",
        "OrderCode": "Mã giao dịch",
        "PayerInfo": "Thông tin người thanh toán",
        "PayerName": "Người thanh toán",
        "PayerPhone": "SĐT người thanh toán",
        "Relationship": "Quan hệ",
        "PayerEmail": "Email",
        "PaymentNote": "Nội dung thanh toán",
        "PaymentInfo": "Khoản thanh toán",
        "Amount": "Giá trị thanh toán",
        "Action": "Thao tác",
        "SearchName": "Tên/ mã học sinh/ mã định danh",
    },
    "Disbursement": {
        "Title": "Chuyển tiền 247",
        "DisbursementByBatch": "Chuyển tiền theo lô",
        "AccountNo": "Số tài khoản",
        "AccountName": "Tên tài khoản",
        "Bank": "Ngân hàng",
        "Object": "Đối tượng chi",
        "Content": "Nội dung chuyển tiền",
        "Balance": "Số dư khả dụng",
        "Request": {
            "Bank": "Ngân hàng",
            "TransferTo": "Chuyển đến",
            "AccountNumber": "Số tài khoản",
            "CardNumber": "Số thẻ",
            "AccountNumberCardNumber": "Số tài khoản/Số thẻ",
            "AccountName": "Tên tài khoản",
            "Amount": "Số tiền giao dịch",
            "Content": "Nội dung chuyển tiền",
            "Code": "Mã xác thực",
            "Transfer": "Chuyển tiền",
            "Check": "Kiểm tra",
            "TextAuthentication": "Vui lòng bật xác thực hai yếu tố để nhận mã xác minh",
            "ReceivingBank": "Ngân hàng nhận tiền",
            "TransferAmount": "Số tiền chuyển",
            "All": "Tất cả",
            "InvalidAccount": "Số thẻ/Số tài khoản không tồn tại",
            "Fee": "Phí",
            "TotalAmount": "Tổng số tiền",
            "IncorrectAuthenticationCode": "Mã xác thực không đúng",
            "AuthenticationCodeRequired": "Mã xác thực là bắt buộc",
        },
        "FileUpload": "File Upload",
        "ChooseFile": "Chọn file thanh toán",
        "ChooseFileText": "Thả file .xlsx của bạn vào đây hoặc",
        "ChooseFileTextLink": "nhấp vào đây để Upload",
        "ChooseFileTextLimit": "(Tối đa 1MB)",
        "Download": "Tải file mẫu",
        "Note": "LƯU Ý",
        "NoteMinMoney": "Số tiền chuyển tối thiểu 1đ/người nhận",
        "NoteBank": "Một số ngân hàng sẽ nhận tiền vào ngày làm việc kế tiếp nếu họ không hỗ trợ chuyển tiền nhanh 24/7",
        "ButtonCheck": "Kiểm tra thông tin",
        "Mimes": "File upload không hợp lệ, vui lòng chọn file khác",
        "Sizes": "File vượt quá dung lượng cho phép, vui lòng chọn file khác",
        "Lines": "Dòng",
        "Description": "Mô tả",
        "TotalAccount": "Tổng số giao dịch:",
        "ValidAccount": "Giao dịch hợp lệ",
        "InvalidAccount": "Giao dịch không hợp lệ",
        "ValidAmount": "Số tiền giao dịch hợp lệ",
        "InvalidAmount": "Số tiền giao dịch không hợp lệ",
        "TotalAmount": "Tổng tiền: ",
        "TransactionAmount": "Số tiền chuyển",
        "Fee": "Phí chuyển tiền",
        "BankCode": "Mã ngân hàng",
        "BankAccount": "Số TK ngân hàng",
        "BankAccountName": "Tên tài khoản",
        "Amount": "Số tiền GD (VND)",
        "DescriptionTrans": "Nội dung chuyển tiền",
        "Status": "Trạng thái",
        "Reason": "Lý do",
        "Action": "Hành động",
        "AccountStatus": {
            "Invalid": "Dữ liệu không hợp lệ",
            "Valid": "Dữ liệu hợp lệ",
            "Processing": "Đang xử lý",
            "InvalidAccount": "Tài khoản không hợp lệ",
            "ValidAccount": "Tài khoản hợp lệ"
        },
        "Validate": {
            "AccountName": "Tên tài khoản không chính xác",
            "Title": "Upload file thất bại, kết quả kiểm tra:",
            "FileUpload": "File upload bị lỗi, vui lòng chọn file khác"
        },
        "TransferMoney": "Chuyển tiền",
        "Return": "Quay lại",
        "HistoryStatus": {
            "Failed": "Thất bại",
            "Initialize": "Chưa xử lý",
            "Processing": "Đang xử lý",
            "Completed": "Hoàn thành"
        },
        "RequestTime": "Thời gian yêu cầu",
        "TotalAccountList": "Tổng số giao dịch",
        "TotalAmountList": "Tổng số tiền",
        "TransactionStatus": "Trạng thái GD",
        "ConfirmSuccess": "Bạn đã gửi chuyển tiền thành công",
        "Continue": "Tiếp tục chuyển tiền",
        "Check": "Kiểm tra",
        "CheckAccount": "Kiểm tra tài khoản",
        "CreatedBy": "Người thực hiện",
        "Yes": "Đồng ý",
        "No": "Hủy bỏ",
        "ConfirmText": "Bạn có chắc chắn muốn hủy bỏ không?",
        "IsChoose": "Tài khoản chọn chuyển tiền",
        "IsNotChoose": "Tài khoản không chọn chuyển tiền",
        "ChoosePlaceholder": "Tài khoản",
        "ActionLog": "Lịch sử thực hiện",
        "ActionDate": "Thời gian thực hiện",
        "ActionName": "Thao tác",
        "Create": "Thêm mới",
        "Update": "Chỉnh sửa",
        "Confirm": "Xác nhận chuyển tiền",
        "IsOffFeeByBatch": "Bạn chưa đăng ký dịch vụ để thực hiện lệnh chuyển tiền"
    },
    "Explain": {
        "Completed": "Khách hàng thực hiện giao dịch thành công",
        "ReceivedMoney": "Khách hàng thanh toán bằng chuyển khoản ngân hàng nhưng không khớp với số tiền cần thanh toán",
        "Cancelled": "Khách hàng chọn hủy tại bước thanh toán",
        "Failed": "Khách hàng thực hiện giao dịch thất bại",
        "Processing": "Hệ thống đang xử lý giao dịch",
        "UnderReview": "Giao dịch chờ phản hồi từ ngân hàng",
        "Linked": "Khách hàng thực hiện thành công liên kết thẻ",
    },
    "BalanceLogWallet": {
        "AllType": "Loại giao dịch",
        "TotalTransaction": "Số giao dịch",
        "TotalAmount": "Tổng tiền",
        // Table
        "No": "STT",
        "Code": "Mã giao dịch",
        "ActionType": "Loại giao dịch",
        "Amount": "Số tiền",
        "Description": "Chi tiết",
        "PaymentAt": "Ngày giao dịch",
    },
    "OrdersHistoryWallet": {
        "OrderCode": "Mã giao dịch",
        "AllStatus": "Trạng thái",
        "Phone": "Số điện thoại",
        "AllType": "Loại giao dịch",
        "TotalTransaction": "Số giao dịch",
        "TotalAmount": "Tổng tiền",
        // Table
        "No": "STT",
        "Code": "Mã giao dịch",
        "ActionType": "Loại giao dịch",
        "Amount": "Số tiền",
        "Status": "Trạng thái",
        "Description": "Mô tả",
        "PaymentAt": "Ngày giao dịch",
        "MerchantName": "Tên đại lý",
    },
    "TransBankInfo": {
        "Step": "Bước",
        "Status": "Trạng thái",
        "Message": "Nội dung"
    },
    "AccountDisbursementList":{
        "Title": "Thông tin tài khoản nạp số dư khả dụng",
        "Description": "Thực hiện chuyển tiền vào số tài khoản/số thẻ để nạp tiền vào số dư khả dụng",
        "No": "STT",
        "Type": "Loại tài khoản",
        "BankAccountType": "Số tài khoản/ Số thẻ",
        "AccountHolderName": "Chủ thẻ/ tài khoản",
        "Bank": "Ngân hàng",
        "Status": "Trạng thái",
        'Active': 'Hoạt động',
        'Inactive': 'Ngừng hoạt động',
        'BankAccountNumber': 'Số tài khoản ngân hàng',
        'BankCardNumber': 'Số thẻ ngân hàng'
    },
    "QR":{
        "NotifySub1": "Chi nhánh và thành viên nhận thông báo giao dịch thanh toán thành công qua tin nhắn zalo",
        "NotifySub2": "Để thao tác nhận thông báo vui lòng xem",
        "NotifySub3": "Hướng dẫn liên kết Zalo OA",
        "No": "STT",
        "Fullname": "Tên thành viên",
        "Phone": "Số điện thoại",
        "VirtualAccount": "Tên chi nhánh",
        "SelectVirtualAccount": "Chọn chi nhánh",
        "ZaloOA": "Cấu trúc liên kết Zalo OA",
        "Action": "Hành động",
        "AddUserLabel":"Thêm mới thành viên",
        "ViewUserLabel":"Thông tin thành viên",
        "EditUserLabel":"Chỉnh sửa thành viên",
        "DeleteUserLabel":"Xóa thành viên",
        "CreateUserSuccess": "Thêm mới thành viên thành công",
        "CreateUserFail": "Thêm mới thành viên thất bại",
        "CreateUserFailPhone": "Số điện thoại thành viên đã tồn tại",
        "UpdateUserSuccess": "Cập nhật thành viên thành công",
        "UpdateUserFail": "Cập nhật thành viên thất bại",
        "ConfirmDeleteUser":"Bạn có chắc chắn muốn xóa thành viên",
        "DeleteUserFail": "Xóa thành viên thất bại",
        "DeleteUserSuccess":"Xóa thành viên thành công",
        "TitleSearch":"Tên chi nhánh",
        "AccountName": "Tên tài khoản",
        "AccountNumber": "Số tài khoản",
        "TransactionAmount": "Số tiền giao dịch",
        "BrandName": "Tên chi nhánh",
        "OrderCode": "Mã giao dịch",
        "CreationTime": "Thời gian khởi tạo",
        "TotalAmount": "Tổng tiền giao dịch",
        "TotalCount": "Tổng giao dịch",
        "DocumentZalo": "Hướng dẫn liên kết Zalo OA",
        "DocumentZaloContent":{
            "step1" :{
                "text1" : "Bước 1: ",
                "text2" : "Truy cập vào ứng dụng Zalo tìm kiếm: ",
                "text3" :'“Cổng thanh toán 9Pay”',
                "text4" : ". Bấm chọn ",
                "text5" : '“Cổng thanh toán 9Pay”',
                "text6" : " => chọn ",
                "text7" : "Quan tâm",
                "img" : "zalo_img_step1_vi",
            },
            "step2" :{
                "text1" : "Bước 2: ",
                "text2" : "Nhắn tin với ",
                "text3" :'“Cổng thanh toán 9Pay” ',
                "text4" : "theo ",
                "text5" : "Cấu trúc liên kết zalo ",
                "text6" : "trên danh sách thành viên để kết nối nhận kết quả (ví dụ: #0987654321)",
                "img" : "zalo_img_step2_vi",
            },
            "step3" :{
                "text1" : "Bước 3: ",
                "text2" : " Nhận thông báo giao dịch thanh toán thành công khi phát sinh"
            },
        },
        "NoTransactions": "Chưa có giao dịch",
    },
    "VirtualAccount": {
        "No": "STT",
        "Type": "Loại tài khoản",
        "BankAccountType": "Số tài khoản/ Số thẻ",
        "AccountHolderName": "Chủ thẻ/ tài khoản",
        "Bank": "Ngân hàng",
        "Status": "Trạng thái",
        'Active': 'Hoạt động',
        'Inactive': 'Không hoạt động',
        "Client": 'Client',
        "BranchName": "Tên chi nhánh",
        "Merchant": "Merchant",
        "Content": "Thực hiện chuyển tiền vào số tài khoản/số thẻ để nạp tiền vào số dư",
        "Heading": "Thông tin tài khoản nạp tiền số dư chờ đối soát",
        "IdentifierCode": "Mã định danh",
        "BankAccountNo": "Số tài khoản",
        "BankAccountName": "Tên tài khoản",
        "Action": "Hành động",
        "CreatedAt": "Thời gian tạo",
        "UpdatedAt": "Thời gian cập nhật",
        "ClientId": "Tên chi nhánh",
        "RequestAmount": "Số tiền thanh toán",
        "CorrectAmountReqForCollection": "yêu cầu tài khoản thu đúng số tiền",
        "AcceptAnyAmount": "Chấp nhận mọi số tiền",
        "CollectTheCorrectAmount": "Thu đúng số tiền",
        "MerchantName": "Đại lý",
        "Placeholder": {
            "ChoosingBank": "Chọn ngân hàng"
        },
        "Validate": {
            "BankIsRequired": "Ngân hàng không được để trống",
            "ClientIdIsRequired": "Tên chi nhánh không được để trống",
            "ClientIdContainsSpecialCharacters": "Tên chi nhánh chứa các ký tự đặc biệt",
            "ClientIdContainsVietNameseCharacters": "Tên chi nhánh chứa các ký tự tiếng Việt",
            "BankAccountNameIsRequired": "Tên tài khoản không được để trống",
            "BankAccountNameContainsSpecialCharacters": "Tên tài khoản chứa các ký tự đặc biệt",
            "BankAccountNameContainsVietNameseCharacters": "Tên tài khoản chứa các ký tự tiếng Việt",
            "RequestAmountIsRequired": "Số tiền yêu cầu không được để trống",
            "BankAccountNameMaxLength": "Tên tài khoản không được dài hơn 32 ký tự",
            "BranchNameMaxLength": "Tên chi nhánh không được dài hơn 200 ký tự",
            "BranchNameIsRequired": "Tên chi nhánh không được để trống",
            "BranchNameContainsSpecialCharacters": "Tên chi nhánh chứa các ký tự đặc biệt",
            "BranchNameContainsVietNameseCharacters": "Tên chi nhánh chứa các ký tự tiếng Việt",
        },
        "ScanToPay": "QUÉT ĐỂ THANH TOÁN",
        "EveryValue": "Mọi giá trị",
        "All": "Tất cả",
        "ModalRequestAmount": "Số tiền yêu cầu",
        "BranchNameLabel": "Tên giúp phân biệt, báo cáo doanh thu giữa các mã QR",
        "BankAccountNameLabel": "Tên hiển thị khi khách hàng quét mã QR",
        "ViewQRCode": "Xem QR Code",
        "ViewHistoryTrans": "Lịch sử giao dịch",
        "QRCode" : "Mã QR",
    },

    "Balance": {
        "Title" : {
            "accountNo": "Tài khoản nhận tiền",
            "accountName": "Tên tài khoản",
            "bank": "Ngân hàng",
            "amount": "Số tiền rút",
            "description": "Nội dung rút tiền",
            "otp": "Mã xác thực",
            "descriptionOtp": "Vui lòng Bật xác thực hai yếu tố để nhận mã xác minh",
            "availableBalance": "Số dư khả dụng",
            
        },
        "Placeholder": {
            "chosseAccountNo": "Chọn tài khoản nhận tiền",
            "amountMin": "Tối thiểu 1,000",
            "description": "Nhập nội dung rút tiền",
            "otp": "Mã google authenticator",

        },
        "Alert": {
            "success": "Thành công",
            "successDescriptionOne": "Yêu cầu rút tiền của bạn đã được tiếp nhận.",
            "sucessDescriptionTwo": "Chúng tôi sẽ xử lý trong vài phút.",
            "successDescriptionThree": "Xin cảm ơn."
        },
        "ButtonTitle": {
            "withdraw": "Rút tiền",
            "close": "Đóng",
            "viewTransaction": "Xem giao dịch",
            "all": "Tất cả",
            "Forward": "Chuyển tiếp",
        }
        
    }
};
