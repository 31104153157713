<template>
  <AppMasterVue >
    <PageTitle :title="title" :breadcrumbs="breadcrumbs"> </PageTitle>
    <section class="section transaction-detail">
      <div class="card" v-if="transaction">
        <div class="row">
          <div class="col-xl-6 left-content">
            <h5 class="card-title">{{ $t('Label.TransactionInfo') }}</h5>

            <div class="row">
              <div class="col-lg-4 col-md-5 label">{{ $t('Transaction.OrderCode') }}</div>
              <div class="col-lg-8 col-md-7">{{ transaction.order_code }}</div>
            </div>

            <div class="row">
              <div class="col-lg-4 col-md-5 label">{{ $t('Transaction.RequestCode') }}</div>
              <div class="col-lg-8 col-md-7" v-if="transaction.transaction_type != TransactionTypeConstant.REFUND">{{ transaction.request_id }}</div>
              <div class="col-lg-8 col-md-7" v-else>{{ (isset(transaction.order_refund) && isset(transaction.order_refund.request_id))
                ? transaction.order_refund.request_id
                : (isset(transaction.refund) && isset(transaction.refund.reference_code) ? transaction.refund.reference_code : '—') }}</div>
            </div>

            <div class="row">
              <div class="col-lg-4 col-md-5 label">{{ $t('Transaction.Type') }}</div>
              <div class="col-lg-8 col-md-7">{{ isset(transaction.transaction_type) ? $t(getTransactionType(transaction.transaction_type)) : '—' }}</div>
            </div>

            <div class="row" v-if="transaction.transaction_type == TransactionTypeConstant.REFUND && isset(transaction.refund)">
              <div class="col-lg-4 col-md-5 label">{{ $t('Transaction.RefundType') }}</div>
              <div class="col-lg-8 col-md-7">{{ isset(transaction.refund.type) ? $t(RefundTypeConstant.TYPE_OF_REFUND[transaction.refund.type]) : '—' }}</div>
            </div>

            <div class="row">
              <div class="col-lg-4 col-md-5 label">{{ $t('Transaction.TradingDate') }}</div>
              <div class="col-lg-8 col-md-7">{{ formatDate(transaction.created_at) }}</div>
            </div>

            <div class="row">
              <div class="col-lg-4 col-md-5 label">{{ $t('Transaction.PaymentValue') }}</div>
              <div class="col-lg-8 col-md-7">{{ isset(transaction.amount) ? (showMoneyFormat(transaction.amount) + " " + transaction.currency) : '—' }}</div>
            </div>

            <div v-if="transaction.transaction_type != TransactionTypeConstant.LINK_CARD">
              <div class="row">
                <div class="col-lg-4 col-md-5 label">{{ $t('Transaction.Currency') }}</div>
                <div class="col-lg-8 col-md-7">{{ isset(transaction.foreign_amount) ? (showMoneyFormat(transaction.foreign_amount) + " " + transaction.foreign_currency) : '—' }}</div>
              </div>
  
              <div class="row">
                <div class="col-lg-4 col-md-5 label">{{ $t('Transaction.ExcRate') }}</div>
                <div class="col-lg-8 col-md-7">{{ isset(transaction.exc_rate) ? showMoneyFormat(transaction.exc_rate) : '—' }}</div>
              </div>
            </div>

            <div v-if="transaction.transaction_type == TransactionTypeConstant.PURCHASE
              || transaction.transaction_type == TransactionTypeConstant.INSTALLMENT
              || transaction.transaction_type == TransactionTypeConstant.LINK_CARD">
              <div class="row">
                <div class="col-lg-4 col-md-5 label" v-if="transaction.transaction_type == TransactionTypeConstant.PURCHASE || transaction.transaction_type == TransactionTypeConstant.LINK_CARD">
                  {{ $t('Transaction.ChargePaymentFee') }}
                </div>
                <div class="col-lg-4 col-md-5 label" v-if="transaction.transaction_type == TransactionTypeConstant.INSTALLMENT">
                  {{ $t('Transaction.ChargePaymentFee') }}
                </div>
                <div class="col-lg-8 col-md-7">{{ isset(transaction.receive_payment_fee) ? showMoneyFormat(transaction.receive_payment_fee) : '—' }} {{ transaction.currency }}</div>
              </div>

              <div class="row">
                <div class="col-lg-4 col-md-5 label">{{ $t('Transaction.ChargeProcessingFee') }}</div>
                <div class="col-lg-8 col-md-7">{{ isset(transaction.receive_processing_fee) ? showMoneyFormat(transaction.receive_processing_fee) : '—' }} {{ transaction.currency }}</div>
              </div>

              <div class="row" v-if="isset(transaction.order_installment)">
                <div class="col-lg-4 col-md-5 label">{{ $t('Transaction.InstallmentFee') }}</div>
                <div class="col-lg-8 col-md-7">{{ isset(transaction.order_installment.received_fee) ? showMoneyFormat(transaction.order_installment.received_fee) : 0 }} VND</div>
              </div>
              <div class="row">
                <div class="col-lg-4 col-md-5 label">{{ $t('Transaction.AmountActually') }}</div>
                <div class="col-lg-8 col-md-7" v-if="isset(transaction.order_installment)">{{ isset(transaction.receive_payment_fee) ? showMoneyFormat(transaction.amount - transaction.receive_payment_fee - transaction.receive_processing_fee - (isset(transaction.order_installment.received_fee) ? transaction.order_installment.received_fee : 0) ) : '—' }}  {{ transaction.currency }}</div>
                <div class="col-lg-8 col-md-7" v-else>{{ isset(transaction.receive_payment_fee) ? showMoneyFormat(transaction.amount - transaction.receive_payment_fee - transaction.receive_processing_fee) : '—' }}  {{ transaction.currency }}</div>
              </div>
              <div class="row">
                <div class="col-lg-4 col-md-5 label">{{ $t('Label.FeePayerTitle') }}</div>
                <div class="col-lg-8 col-md-7">{{ $t(showFeePayerTransactions(transaction.partner_fee)) }}</div>
              </div>
            </div>

            <div v-if="transaction.transaction_type == TransactionTypeConstant.REFUND">
              <div class="row">
                <div class="col-lg-4 col-md-5 label">{{ $t('Transaction.RefundPaymentFee') }}</div>
                <div class="col-lg-8 col-md-7">{{ isset(transaction.receive_payment_fee) ? showMoneyFormat(transaction.receive_payment_fee) : '—' }}</div>
              </div>

              <div class="row">
                <div class="col-lg-4 col-md-5 label">{{ $t('Transaction.ChargeProcessingFee') }}</div>
                <div class="col-lg-8 col-md-7">{{ isset(transaction.receive_processing_fee) ? showMoneyFormat(transaction.receive_processing_fee) : '—' }}</div>
              </div>

              <div class="row">
                <div class="col-lg-4 col-md-5 label">{{ $t('Transaction.ActualAmountMinus') }}</div>
                <div class="col-lg-8 col-md-7" v-if="isset(transaction.order_installment)">
                  {{ isset(transaction.receive_payment_fee) ? showMoneyFormat(transaction.amount - transaction.receive_payment_fee + transaction.receive_processing_fee -
                    (isset(orderInstallment.refund_received_fee) ? orderInstallment.refund_received_fee : 0) ) : '—' }}</div>
                <div class="col-lg-8 col-md-7" v-else>{{ isset(transaction.receive_payment_fee) ? showMoneyFormat(transaction.amount - transaction.receive_payment_fee + transaction.receive_processing_fee) : '—' }}</div>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-4 col-md-5 label">{{ $t('Transaction.PaymentMethod') }}</div>
              <div class="col-lg-8 col-md-7">{{ $t(showAllTransactionMethod()[transaction.method]) }}</div>
            </div>

            <div class="row">
              <div class="col-lg-4 col-md-5 label">{{ $t('Transaction.Status') }}</div>
              <div class="col-lg-8 col-md-7">
                <span class="transaction-status" v-html="showTransactionStatusListHTML(transaction.status, $t)"></span>
              </div>
            </div>

            <div class="row" v-if="transaction.status === TransactionStatusConstant.CANCELLED || transaction.status === TransactionStatusConstant.FAILED">
              <div class="col-lg-4 col-md-5 label">{{ $t('Transaction.FailureReason') }}</div>
              <div class="col-lg-8 col-md-7">{{ isset(transaction.code) ? transaction.code.code + ' | ' + transaction.code.message + ' | ' + (this.user.user.lang === 'vi' ? transaction.code.content_vi : transaction.code.content_en) : transaction.error_code }}</div>
            </div>
          </div>

          <div class="col-xl-6 right-content" v-if="transaction.channel == ChannelConstant.INVOICE && isset(transaction.request_id)">
            <h5 class="card-title">{{ $t('Label.InvoiceInfo') }}</h5>

            <div class="row">
              <div class="col-lg-4 col-md-5 label ">{{ $t('Invoice.InvoiceCode') }}</div>
              <div class="col-lg-8 col-md-7">{{ transaction.request_id }}</div>
            </div>

            <div class="row">
              <div class="col-lg-4 col-md-5 label">{{ $t('Invoice.Title') }}</div>
              <div class="col-lg-8 col-md-7">{{ invoice.title }}</div>
            </div>

            <div class="row">
              <div class="col-lg-4 col-md-5 label">{{ $t('Invoice.Note') }}</div>
              <div class="col-lg-8 col-md-7">{{ isset(invoice.note) ? invoice.note : '—' }}</div>
            </div>

            <div class="row">
              <div class="col-lg-4 col-md-5 label">{{ $t('Customer.CustomerName') }}</div>
              <div class="col-lg-8 col-md-7">{{ isset(customer.customer_name) ? customer.customer_name : '—' }}</div>
            </div>

            <div class="row">
              <div class="col-lg-4 col-md-5 label">{{ $t('Customer.Phone') }}</div>
              <div class="col-lg-8 col-md-7">{{ isset(customer.customer_phone) ? customer.customer_phone : '—' }}</div>
            </div>

            <div class="row">
              <div class="col-lg-4 col-md-5 label">{{ $t('Customer.Address') }}</div>
              <div class="col-lg-8 col-md-7">{{ isset(customer.customer_address) ? customer.customer_address : '—' }}</div>
            </div>

            <div class="row">
              <div class="col-lg-4 col-md-5 label">{{ $t('Invoice.StatusInvoice') }}</div>
              <div class="col-lg-8 col-md-7">
                <span class="transaction-status" v-if="isset(invoice.status)" v-html="showInvoiceStatusDetailHTML(invoice.status, $t)"></span>
                <span v-else>—</span>
              </div>
            </div>
          </div>

          <div class="col-xl-6 left-content" v-if="transaction.transaction_type != TransactionTypeConstant.REFUND">
            <h5 class="card-title">{{ $t('Label.CardInfo') }}</h5>

            <div class="row">
              <div class="col-lg-4 col-md-5 label ">{{ $t('BankInfo.BankCode') }}</div>
              <div class="col-lg-8 col-md-7">{{ transaction.bank_code }}</div>
            </div>

            <div class="row">
              <div class="col-lg-4 col-md-5 label">{{ $t('BankInfo.CardNumber') }}</div>
              <div class="col-lg-8 col-md-7">{{ transaction.card_number }}</div>
            </div>

            <div class="row">
              <div class="col-lg-4 col-md-5 label">{{ $t('Transaction.CardName') }}</div>
              <div class="col-lg-8 col-md-7">{{ cardName }}</div>
            </div>

            <div class="row">
              <div class="col-lg-4 col-md-5 label">{{ $t('Transaction.ClientIP') }}</div>
              <div class="col-lg-8 col-md-7">{{ transaction.client_ip }}</div>
            </div>

            <div class="row" v-if="(transaction.method == TransactionMethodConstant.QR_PAY ||  transaction.method == TransactionMethodConstant.COLLECTION) && transaction.extra_data ">
              <div class="col-lg-4 col-md-5 label">{{ $t('Transaction.Remark') }}</div>
              <div class="col-lg-8 col-md-7">{{ transaction.extra_data.remark }}</div>
            </div>
            <div v-if="(transaction.method == TransactionMethodConstant.QR_PAY ||  transaction.method == TransactionMethodConstant.COLLECTION) && transaction.extra_data ">
              <div class="row">
                <div class="col-lg-4 col-md-5 label">{{ $t('Transaction.SenderBankName') }}</div>
                <div class="col-lg-8 col-md-7">{{ transaction.extra_data.sender_bank_name }}</div>
              </div>  
              <div class="row">
                <div class="col-lg-4 col-md-5 label">{{ $t('Transaction.SenderBankAccountName') }}</div>
                <div class="col-lg-8 col-md-7">{{ transaction.extra_data.sender_bank_account_name }}</div>
              </div>  
              <div class="row">
                <div class="col-lg-4 col-md-5 label">{{ $t('Transaction.SenderBankAccountNo') }}</div>
                <div class="col-lg-8 col-md-7">{{ transaction.extra_data.sender_bank_account_no }}</div>
              </div>
            </div>
          </div>

          <div class="col-xl-6 right-content" v-if="transaction.transaction_type == TransactionTypeConstant.INSTALLMENT && isset(orderInstallment)">
            <h5 class="card-title">{{ $t('Label.InstallmentInfo') }}</h5>

            <div class="row">
              <div class="col-lg-4 col-md-5 label ">{{ $t('Installment.CardName') }}</div>
              <div class="col-lg-8 col-md-7">{{ orderInstallment.card_name }}</div>
            </div>

            <div class="row">
              <div class="col-lg-4 col-md-5 label">{{ $t('Installment.BankInstallment') }}</div>
              <div class="col-lg-8 col-md-7">{{ orderInstallment.bank_installment }}</div>
            </div>

            <div class="row">
              <div class="col-lg-4 col-md-5 label">{{ $t('Installment.Period') }}</div>
              <div class="col-lg-8 col-md-7">{{ isset(orderInstallment.period) ? $t('Installment.PeriodShow', {period: orderInstallment.period}) : '—' }}</div>
            </div>

            <div class="row">
              <div class="col-lg-4 col-md-5 label">{{ $t('Installment.FeeBearer') }}</div>
              <div class="col-lg-8 col-md-7">{{ isset(orderInstallment.fee_bearer) ? $t(showHandleBearer(orderInstallment.fee_bearer)) : '—' }}</div>
            </div>

            <div class="row">
              <div class="col-lg-4 col-md-5 label">{{ $t('Installment.ReceivedFee') }}</div>
              <div class="col-lg-8 col-md-7">{{ isset(orderInstallment.received_fee) ? showMoneyFormat(orderInstallment.received_fee) : '—' }}</div>
            </div>

            <div class="row">
              <div class="col-lg-4 col-md-5 label">{{ $t('Installment.RefundReceivedFee') }}</div>
              <div class="col-lg-8 col-md-7">{{ isset(orderInstallment.refund_received_fee) ? showMoneyFormat(orderInstallment.refund_received_fee) : '—' }}</div>
            </div>

            <div class="row">
              <div class="col-lg-4 col-md-5 label">{{ $t('Installment.ClientPhone') }}</div>
              <div class="col-lg-8 col-md-7">{{ orderInstallment.client_phone }}</div>
            </div>

            <div class="row">
              <div class="col-lg-4 col-md-5 label">{{ $t('Installment.Status') }}</div>
              <div class="col-lg-8 col-md-7">{{ isset(orderInstallment.status_installment) ?
                  (transaction.channel === 3 && transaction.status === 5 ||  transaction.channel !== 3 ? $t(showInstallmentStatus(orderInstallment.status_installment)) : 'Chưa thực hiện')
                   : '—' }}</div>
            </div>

            <div class="row">
              <div class="col-lg-4 col-md-5 label">{{ $t('Installment.Note') }}</div>
              <div class="col-lg-8 col-md-7">{{ orderInstallment.note }}</div>
            </div>
          </div>
        </div>

        <div class="row" v-if="transaction.channel == ChannelConstant.MC_HOSTED && isset(transaction.transaction_bank_info)">
          <div class="col-xl-6 left-content">
            <h5 class="card-title mb-2">{{ $t('Label.PaymentProcessingInfo') }}</h5>
            <h6 class="mb-2 card-subtitle">{{ $t('Label.Overview') }}</h6>
            <div class="table-responsive tbl-step mb-2">
              <table class="table table-bordered table-hover">
                <thead>
                  <tr>
                    <th>{{ $t('TransBankInfo.Step') }}</th>
                    <th>{{ $t('TransBankInfo.Status') }}</th>
                    <th>{{ $t('TransBankInfo.Message') }}</th>
                  </tr>
                </thead>
                <tbody>
                <tr v-for="(record, index) in transactionStepShow"
                    :key="index"
                    :class="showStatusStepClass(record.status)">
                  <td>{{ $t(record.step) }}</td>
                  <td>{{ $t(showStatusStep(record.status)) }}</td>
                  <td>{{ $t(record.message) }}</td>
                </tr>

                <tr v-if="transaction.status == TransactionStatusConstant.COMPLETED && transaction.transaction_type == TransactionTypeConstant.INSTALLMENT && isset(orderInstallment)"
                  :class="showStatusStepInstallmentClass(orderInstallment.status_installment)">
                  <td>{{ $t('Constant.TransactionType.Installment') }}</td>
                  <td>{{ $t(showStatusStepInstallment(orderInstallment.status_installment)) }}</td>
                  <td>{{ $t(showStatusStepInstallment(orderInstallment.status_installment)) }}</td>

                </tr>
                <tr v-if="transaction.status != TransactionStatusConstant.COMPLETED && transaction.transaction_type == TransactionTypeConstant.INSTALLMENT && isset(orderInstallment)"
                  :class="step_unrealized">
                  <td>{{ $t('Constant.TransactionType.Installment') }}</td>
                  <td>{{ $t('Constant.TransBankInfo.NotOccur') }}</td>
                  <td></td>
                </tr>
                </tbody>
              </table>
            </div>
            <h6 class="mb-2 card-subtitle">{{ $t('Label.Detail') }}</h6>
            <div class="list-step">
              <div v-for="(record, index) in transaction.transaction_bank_info" :key="index">
                <div v-if="!transactionStepCheckView.includes(record.step)">
                  <p>
                    {{ showFormatDate(record.created_at, "HH:mm:ss DD/MM/YYYY") }}: Step {{ isset(TransBankInfo.TRANSACTION_STEP[record.step]) ? $t(TransBankInfo.TRANSACTION_STEP[record.step]) : '--' }} - {{ $t(showStatusStep(TransBankInfo.STATUS_PROCESSING_INT)) }}
                  </p>

                  <p v-if="record.status !== TransBankInfo.STATUS_PROCESSING_INT">
                    {{ showFormatDate(record.updated_at, "HH:mm:ss DD/MM/YYYY") }}: Step {{ isset(TransBankInfo.TRANSACTION_STEP[record.step]) ? $t(TransBankInfo.TRANSACTION_STEP[record.step]) : '--' }} - {{ $t(showStatusStep(record.status)) }}
                  </p>
                </div>
              </div>

              <div v-for="(record_detail, index_detail) in transaction.transaction_detail" :key="index_detail">
                <p>
                  {{ showFormatDate(record_detail.created_at, "HH:mm:ss DD/MM/YYYY") }}: Step {{ isset(TransBankInfo.TRANSACTION_STEP[record_detail.step]) ? $t(TransBankInfo.TRANSACTION_STEP[record_detail.step]) : '--' }} - {{ $t(showStatusStep(TransBankInfo.STATUS_PROCESSING_INT)) }}
                </p>

                <p v-if="record_detail.status !== TransBankInfo.STATUS_PROCESSING_INT">
                  {{ showFormatDate(record_detail.updated_at, "HH:mm:ss DD/MM/YYYY") }}: Step {{ isset(TransBankInfo.TRANSACTION_STEP[record_detail.step]) ? $t(TransBankInfo.TRANSACTION_STEP[record_detail.step]) : '--' }} - {{ $t(showStatusStep(record_detail.status)) }}
                </p>
              </div>

            </div>
          </div>
        </div>

        <div class="row btn-content">
          <div class="text-center">
<!--            <a-button type="primary" style="margin-right:10px;" v-if="displayBtnInstallment" @click="showModal(transaction.order_code)">{{ $t('Button.CancelInstallment') }}</a-button>-->
            <a-button type="primary" style="margin-right:10px" @click="toCreateRefund" v-if="checkShowButtonRefund() && transaction.transaction_type !== 8">{{ $t('Button.Refund') }}</a-button>
<!--            <a-button onclick="history.back()">{{ $t('Button.Back') }}</a-button>-->
          </div>
        </div>
      </div>

      <div>
        <a-modal
            :title="$t('Notice.Title.Confirm')"
            :visible="visible"
            :confirm-loading="confirmLoading"
            @cancel="handleCancel"
            @ok="handleOk"
        >
          <p>{{ $t('Notice.Message.ConfirmSendRequestCancelInstallment') }}</p>
        </a-modal>
      </div>
    </section>
  </AppMasterVue>
</template>

<script>

import AppMasterVue from '../../layout/AppMaster.vue'
import PageTitle from '@/components/PageTitle'
import { mapGetters } from 'vuex';
import helper from "../../until/helper";
import TransactionTypeConstant from "../../constants/TransactionType";
import TransactionStatusConstant from "../../constants/TransactionStatus";
import InvoiceStatusConstant from "../../constants/InvoiceStatus";
import InstallmentConstant from "../../constants/InstallmentStatus";
import ChannelConstant from "../../constants/Channel";
import RefundTypeConstant from "../../constants/RefundType";
import TransBankInfo from "../../constants/TransBankInfo";
import moment from "moment/moment";
import transBankInfo from "../../constants/TransBankInfo";
import TransactionMethodConstant from "../../constants/TransactionMethod";

export default {
  name: 'TransactionDetail',
  components: {
    AppMasterVue,
    PageTitle
  },
  data() {
    return {
      TransactionTypeConstant: TransactionTypeConstant,
      ChannelConstant: ChannelConstant,
      RefundTypeConstant: RefundTypeConstant,
      TransactionStatusConstant: TransactionStatusConstant,
      InvoiceStatusConstant: InvoiceStatusConstant,
      TransBankInfo:TransBankInfo,
      title: 'Title.TransactionDetail',
      breadcrumbs: [
        {
          name: 'Title.Home',
          routeName: 'Home',
          active: true,
        },
        {
          name: 'Sidebar.TransactionManagement',
          routeName: null,
          active: false
        },
        {
          name: 'Sidebar.TransactionList',
          routeName: 'ListTransaction',
          active: true
        },
        {
          name: 'Title.TransactionDetail',
          routeName: 'TransactionDetail',
          active: true
        }
      ],
      listPossibleStatusRefund: [
        TransactionStatusConstant.COMPLETED,
        TransactionStatusConstant.RECEIVED_MONEY,
      ],
      cardName: '',
      checkHasReverseStepSuccess: false,
      transactionStep: TransBankInfo.TRANSACTION_STEP,
      TransactionMethodConstant: TransactionMethodConstant,
      transactionStepShow: [],
      transactionStepCurrent: [],
      transactionStepCurrentReal: [],
      transactionStepCheckView: [TransBankInfo.STEP_REVERSE_AUTH, TransBankInfo.STEP_CAPTURE],
      columns: [
        {
          key: "step",
          slots: { title: "customTitleStep" },
          scopedSlots: { customRender: "step" },
        },
        {
          key: "status",
          slots: { title: "customTitleStatus" },
          scopedSlots: { customRender: "status" },
        },
        {
          key: "message",
          slots: { title: "customTitleMessage" },
          scopedSlots: { customRender: "message" },
        },
      ],
      displayBtnInstallment: false,
      visible: false,
      confirmLoading: false,
      transOrderCode: null,
      userInfo: JSON.parse(localStorage.getItem('user')).profile,
      sttUnDisplayCancelBtn: InstallmentConstant.STT_DISPLAY_CANCEL_INSTALLMENT_CONVERSION,
      unDisplayRefundBtn: false,
      // statusHoldMoney: [0, 1, 2]
    }
  },
  computed: {
    ...mapGetters({
      transaction: 'transaction/getTransaction',
      invoice: 'transaction/getInvoice',
      customer: 'transaction/getCustomer',
      orderInstallment: 'transaction/getOrderInstallment',
      user: 'authentications/getUserLogin',
    }),
  },
  mounted() {
    this.$store.dispatch('transaction/fetchTransaction', { orderCode : this.$route.params.order_code }).then(response => {
      if (response.data) {
        if (this.transaction.card_info != null) {
          this.cardName = JSON.parse(this.transaction.card_info).card_name;
        } else {
          this.cardName = this.transaction.card_name != '' ? this.transaction.card_name : '—';
        }

        if(this.transaction.transaction_bank_info != null) {
          this.transaction.transaction_bank_info.forEach((value) => {
            this.transactionStepCurrent.push({
              'step': value.step,
              'status': value.status,
              'message': value.message,
            });

            if(value.step === TransBankInfo.STEP_REVERSE_AUTH && value.status === TransBankInfo.STATUS_COMPLETED_INT) {
              this.checkHasReverseStepSuccess = true;
            }
          });

          //Convert key array
          if(this.transactionStepCurrent.length > 0) {
            this.transactionStepCurrent.forEach((value) => {
              this.transactionStepCurrentReal[value.step] = value;
            });

            for (let [key, value] of Object.entries(this.transactionStep)) {
              if(key === TransBankInfo.STEP_REVERSE_AUTH) continue;

              this.transactionStepShow.push({
                key: key,
                step: value,
                status: typeof(this.transactionStepCurrentReal[key]) !== 'undefined' ? (this.checkHasReverseStepSuccess && key === transBankInfo.STEP_AUTHORIZE ? transBankInfo.STATUS_REVERSAL_INT : this.transactionStepCurrentReal[key].status) : -2,
                message: typeof(this.transactionStepCurrentReal[key]) !== 'undefined' ? this.transactionStepCurrentReal[key].message : ''
              });
            }
          }

          // console.log(this.transactionStepCurrentReal.length);
        }

        if(this.transaction.transaction_detail != null) {
          this.transaction.transaction_detail.forEach((value) => {
            if(value.step === TransBankInfo.STEP_AUTHORIZE) {
              this.transactionStepCheckView = [TransBankInfo.STEP_REVERSE_AUTH, TransBankInfo.STEP_CAPTURE, TransBankInfo.STEP_AUTHORIZE];
            }
          });
        }

        if(this.isset(response.data.order_installment)){
          if(this.sttUnDisplayCancelBtn.includes(response.data.order_installment.status_installment)){
            if(response.data.channel === 3 && response.data.status === 5 || response.data.channel !== 3){
              this.displayBtnInstallment = true;
            }
          }
          let maxRefund = response.data.amount_requested != '—' ?
              (response.data.amount - response.data.amount_requested === 0 ? 0
                  : response.data.amount - response.data.amount_requested - response.data.refund_amount -
                  (this.isset(response.data.order_installment.refund_received_fee)
                  && response.data.order_installment.refund_received_fee === 0 ? response.data.installment_conversion_fee : 0)) : 0,
              installmentConversionFee =  this.isset(response.data.order_installment.refund_received_fee)
              && response.data.order_installment.refund_received_fee === 0 ? response.data.installment_conversion_fee : 0;
          if(this.isset(response.data.order_installment) && maxRefund <= 0 && installmentConversionFee == 0){
            this.unDisplayRefundBtn = true
          }
        }

        // if(this.isset(this.transaction.transaction_hold_money)) {
        //   this.transaction.transaction_hold_money.forEach(data => {
        //     if(this.statusHoldMoney.includes(data.status)) {
        //       this.unDisplayRefundBtn = true;
        //     }
        //   });
        // }
        if(typeof this.transaction.extra_data !== 'undefined'){
          this.transaction.extra_data =JSON.parse(this.transaction.extra_data);
        }
      }
    });
  },
  methods: {
    showFormatDate(data = null, format = "DD/MM/YYYY HH:mm:ss") {
      if (moment(data).isValid) {
        return moment(data).format(format);
      }
      return moment().format(format);
    },
    formatDate: helper.formatDate,
    showMoneyFormat: helper.showMoneyFormat,
    isset: helper.isset,
    getTransactionType: helper.getTransactionType,
    showStatus: helper.showStatus,
    showStatusInvoice: helper.showStatusInvoice,
    showHandleBearer: helper.showHandleBearer,
    showInstallmentStatus: helper.showInstallmentStatus,
    showAllTransactionMethod: helper.showAllTransactionMethod,
    checkPermission: helper.checkPermission,
    checkIsMasterMerchant: helper.checkIsMasterMerchant,
    checkMerchantActive: helper.checkMerchantActive,
    showFeePayerTransactions: helper.showFeePayerTransactions,
    showStatusStep: helper.showStatusStep,
    showStatusStepClass: helper.showStatusStepClass,
    getTransactionStep: helper.getTransactionStep,
    showInvoiceStatusDetailHTML: helper.showInvoiceStatusDetailHTML,
    showTransactionStatusListHTML: helper.showTransactionStatusListHTML,
    showStatusStepInstallmentClass: helper.showStatusStepInstallmentClass,
    showStatusStepInstallment: helper.showStatusStepInstallment,
    toCreateRefund() {
      this.$router.push({name: 'UpdateRefund', params: {paymentNo: this.transaction.order_code}});
    },
    checkShowButtonRefund() {
      return this.checkPermission(this.user, 'canAddRefund')
        && !this.checkIsMasterMerchant(this.user)
        && this.checkMerchantActive(this.user)
        && this.listPossibleStatusRefund.includes(this.transaction.status)
        && this.transaction.transaction_type != TransactionTypeConstant.REFUND
        && this.transaction.transaction_type != TransactionTypeConstant.INSTALLMENT_REFUND
        && this.transaction.amount > this.transaction.refund_amount
        && !this.unDisplayRefundBtn;
    },
    showModal(orderCode) {
      this.visible = true;
      this.transOrderCode = orderCode;
    },
    handleCancel() {
      this.visible = false;
      this.transOrderCode = null;
    },
    handleOk() {
      this.confirmLoading = true;
      let params = {
        order_code: this.transOrderCode,
        email: this.userInfo.email,
        merchant_name: this.userInfo.name
      }
      this.pushNotiCancelInstallment(params)
      setTimeout(() => {
        this.visible = false;
        this.confirmLoading = false;
      }, 1000);
    },
    pushNotiCancelInstallment(params){
      this.$store.dispatch("transaction/pushNotiCancelInstallment", params)
          .then(response => {
            if(response.data.code === 200){
              this.showNotice(
                  'success',
                  this.$t('Notice.Title.Notice'),
                  this.$t('Notice.Message.NotiCancelInstallmentSuccess')
              );
              location.reload()
            }
            else{
              this.showNotice(
                  'success',
                  this.$t('Notice.Title.Notice'),
                  this.$t('Notice.Message.NotiCancelInstallmentFail')
              );
            }
          })
    },
    showNotice(type, title, content) {
      this.$notification[type]({
        message: title,
        description: content,
        duration: 5,
        style: {
          "margin-top": "50px"
        },
      });
    },
  }
}
</script>

<style>
  .left-content, .right-content {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }

  .btn-content {
    padding: 20px 0;
  }
  .btn-content .btn {
    margin: 0 7px;
    color: #fff;
  }

  .left-content .row, .right-content .row {
    font-size: 14px;
    line-height: 22px;
    color: #000000;
    padding-bottom: 18px;
  }
  .box-step__transaction h5{
    color: #212121;
    font-size: 13px;
  }
  .tbl-step{
    padding-left: 30px;
    position: relative;
  }
  .tbl-step::before{
    content: '';
    width: 1px;
    height: calc(100% - 1rem - 17px);
    background: #E5E5E5;
    position: absolute;
    transform: translate(-15px, 0);
    display: block;
  }
  .tbl-step .table>:not(:first-child){
    border-top: 0;
  }
  .tbl-step table tbody tr:first-child{
    border-top: 0;
  }
  .tbl-step table tbody tr{
    position: relative;
  }
  .tbl-step table tr th{
    background-color: #F5F5F6;
  }
  .tbl-step table tr::before{
    content: '';
    width: 11px;
    height: 11px;
    background: gray;
    border-radius: 50%;
    display: block;
    position: relative;
    left: 0;
    top: 0;
    z-index: 2;
    margin-top: 14px;
    margin-left: -20px;
  }
  .tbl-step table thead tr::before{
    background: transparent;
  }
  .list-step p{
    margin-bottom: 5px;
  }
  .tbl-step table tr.step_completed::before{
    background: green;
  }
  .tbl-step table tr.step_failed::before{
    background: red;
  }
  .tbl-step table tr.step_pending::before{
    background: yellow;
  }
  @media only screen and (max-width: 600px) {
    .tbl-step table {
      width: 350px;
    }
  }
  .transaction-status p{
    max-width: 130px;
  }
</style>
